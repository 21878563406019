@import "../../../styles/variables.scss";
@import "../../../styles/common.scss";

.liveTraitsTerminalPageWrapper {
  padding-top: 50px;
  display: flex;
  margin-bottom: 50px;
  position: relative;

  .liveTraitsTerminalLeftSection {
    height: fit-content;
    flex: 1;
    flex-wrap: wrap;
    padding: 20px;
    background: #121212;
    border: 1px solid #464646;
    box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-right: 20px;

    &Header {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #9a9a9a;
    }

    .nftDetailsWrapper {
      position: relative;
      margin: 50px 0;
      background-color: #464646;
      padding: 12px;
      border-radius: 10px;
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 8px; /* added */
        width: 0;
        border-left: 145px solid transparent;
        border-right: 150px solid transparent;
      }
      &::before {
        bottom: 100%;
        border-bottom: 25px solid #464646;
      }
      &::after {
        top: 100%;
        width: 0;
        border-top: 25px solid #464646;
      }

      .nftDetails {
        & > * {
          margin-bottom: 10px;
          &:last-child {
            margin: 0;
          }
        }

        .nftImageWrapper {
          z-index: 0;
          text-align: center;
          margin-bottom: 10px;
          background-color: #464646;
          width: 100%;
          height: 100%;
          position: relative;
          .nft-image-background {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: -1;
            width: 100%;
            height: 100%;
            -webkit-filter: blur(10px); /* Safari 6.0 - 9.0 */
            filter: blur(10px);
          }
          .nft-image {
            width: 200px;
            height: 200px;
            object-fit: contain;
            border-radius: 10px;
          }
        }

        .leftBLockNFTDetailsWrapper {
          background: #d7ff3a;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          padding: 5px;
          text-transform: uppercase;
          align-items: center;
          position: relative;

          .checkboxWrapper {
            display: flex;
            align-items: center;

            .checkboxInput {
              height: 16px;
              width: 16px;
              background: transparent;
              border: 1px solid #000000;
              border-radius: 2px;
              cursor: pointer;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .liveTraitsTerminalRightSection {
    flex: 2;
    flex-wrap: wrap;

    .liveTraitsTerminalRightTopSection {
      padding: 15px 25px;
      background: #121212;
      border: 1px solid #464646;
      box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &Title {
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        margin-right: 10px;
      }

      &Data {
        background: #000000;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        color: #464646;
        padding: 10px;
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
    }
    .liveTraitsTerminalRightBottomSection {
      margin: 20px 0;
      padding: 30px 40px;
      background: #121212;
      border: 1px solid #464646;
      box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
      border-radius: 10px;

      &Title {
        font-weight: 400;
        font-size: 32px;
        color: #ffffff;
        display: block;
      }
      &Editor {
        overflow: hidden !important;
        margin: 20px 0;
      }
      &Support {
        font-weight: 500;
        font-size: 16px;
        color: #9a9a9a;
        .support-link {
          color: $color-blue-0;
          &:link{
          color: $color-blue-0;
          }
          &:visited{
          color: $color-blue-0;
          }
          &:hover{
          color: $color-blue-0;
          }
        }
      }
    }
    .liveTraitsTerminalEditorCodeCopyButtonSection {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1.6px;
      color: #ffffff;
      background: $blue-gradient;
      border-radius: 52px;
      width: 100px;
      float: right;
      cursor: pointer;
      padding: 5px 7px;
    }
  }
}

.copySuccessPopupModal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .copySuccessPopupModalContent {
    margin: 0 auto;
    overflow: auto;
  }
}

#footer {
  position: absolute;
  height: 102px;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  object-fit: contain;
}

.toast-message {
  background: $blue-gradient;
  color: $color-white-0;
  font-size: 20px;
  padding: 10px 20px;
}

@media screen and (min-width: 1000px) {
  .liveTraitsTerminalPageWrapper {
    max-width: 992px;
    margin: 0 auto;
  }
}
