@import "../../styles/common.scss";

.revisionsSection {
  background-image: url("../../assets/images/revisions/revisionsbackground.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.common-btn-url {
  font-size: 12px !important;
  // @include white-border-btn;
  border: 2px solid white;
  padding: 0.5rem 0.9rem;
  color: white;
  border-radius: 50rem;
}

.common-btn-url:hover {
  // @include white-border-btn;
  border: 2px solid #fe0074;
}

.revisionsContainer {
  padding: 12.3rem 0 10.6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .revisionsCard {
    width: 540px;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.2);
    border: 2px solid $color-white-0;
    border-radius: 1rem;
    padding-bottom: 20px;
    &:last-child {
      padding: 0 3.2rem 3.6rem;
    }
  }

  .collectionContent {
    text-align: center;
    padding: 7.8rem 4.1rem 3.5rem;

    .imageWrapper {
      width: 25rem;
      height: 25rem;
      margin: 0 auto 2.6rem;
      @media only screen and (max-width: 600px) {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    h2 {
      @extend %typeface-regular-48;
      color: $color-white-0;
      margin-bottom: 0.6rem;
    }
    h3 {
      @extend %typeface-regular-32;
      color: $color-white-0;
      margin-bottom: 0.5rem;
    }
    h4 {
      color: $color-white-0;
      margin-bottom: 1rem;
    }
    p {
      @extend %typeface-regular-18;
      color: $color-white-0;
      text-align: left;
    }
  }

  .collectionModifiedMeta {
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
    div {
      flex-basis: 33.333%;
      padding: 1rem;
      // &:last-child {
      //   border: none;
      // }
    }
    .half-border {
      border-bottom: 1px solid $color-white-0;
    }
    .right-border {
      border-right: 1px solid $color-white-0;
      border-bottom: 1px solid $color-white-0;
    }
    .left-border {
      border-left: 1px solid $color-white-0;
      border-bottom: 1px solid $color-white-0;
    }

    h4 {
      @extend %typeface-semibold-16;
      color: $color-brand-magenta-0;
      margin-bottom: 1rem;
    }
    p {
      // @extend %typeface-bold-24;
      font-size: 14px;
      color: $color-white-0;
    }
  }
  .collectionModified {
    border-top: 1px solid $color-white-0;
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
    div {
      flex-basis: 33.333%;
      padding: 1rem;
      border-right: 1px solid $color-white-0;
      &:last-child {
        border: none;
      }
    }
    h4 {
      @extend %typeface-semibold-16;
      color: $color-brand-magenta-0;
      margin-bottom: 1rem;
    }
    p {
      // @extend %typeface-bold-24;
      font-size: 14px;
      color: $color-white-0;
    }
  }

  .collectionAddress {
    display: flex;
    border-top: 1px solid $color-white-0;
    border-bottom: 1px solid $color-white-0;
    padding: 1rem 0;
    align-items: center;
    justify-content: center;
    h4 {
      color: $color-brand-magenta-0;
      margin-right: 1.6rem;
      margin-bottom: 0;
    }
    p {
      // @extend %typeface-bold-24;
      font-size: 14px;
      color: $color-white-0;
      margin-bottom: 0;
    }
  }

  .logHeading {
    h1 {
      padding: 5.5rem 0 5rem;
      color: $color-white-0;
      @extend %typeface-semibold-32;
      text-align: center;
    }
  }

  .logDetails {
    position: relative;
    padding-left: 2px;
    margin: 0 2rem;
    &::before {
      content: "";
      position: absolute;
      left: 0rem;
      top: 1rem;
      width: 2px;
      height: 97%;
      background-color: $color-white-0;
    }
    // &::after {
    //   content: '';
    //   width: 103%;
    //   height: 30%;
    //   position: absolute;
    //   left: -1rem;
    //   bottom: -3rem;
    //   background: linear-gradient(transparent, #071E27);
    // }
  }

  .logText {
    position: relative;
    padding-left: 3.6rem;
    &::before {
      content: "";
      position: absolute;
      left: -1rem;
      top: 0.5rem;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 3rem;
      background: $blue-gradient;
      border: 1px solid $color-white-0;
    }
    .logTiming {
      color: $color-white-0;
    }
    span.logDate {
      font-size: 14px;
    }
    span.logTime {
      font-size: 14px;
    }
    .logContent {
      padding-top: 1.3rem;
      padding-bottom: 1.9rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
      margin-bottom: 2.1rem;
      overflow: hidden;
    }
    .logData {
      display: flex;
      margin-bottom: 0.3rem;

      p {
        display: inline-block;
        @extend %typeface-regular-16;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    label {
      @extend %typeface-regular-16;
      color: rgba(255, 255, 255, 0.6);
      min-width: 6.5rem;
      margin-right: 0.6rem;
    }
  }

  .logButton {
    padding-top: 3rem;
    .common-btn {
      @include gradient-btn;
      @extend %typeface-semibold-24;
      padding: 2rem 2rem 1.9rem;
      width: 100%;
      display: block;
      border-radius: 3rem;
    }
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  pointer-events: none;
  z-index: 9999;
}

.MuiCircularProgress-colorPrimary {
  height: 80px;
  width: 80px;
}

@media screen and (max-width: 480px) {
  .collectionModifiedMeta {
    display: block !important;
  }
  .collectionModified {
    display: block !important;
  }
}
