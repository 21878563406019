@import "../../styles/common.scss";

.dashboard {
  padding-top: 4.7rem;
  .noteContainer {
    margin-bottom: 4.7rem;
    position: relative;
    padding: 1.9rem 4.4rem;
    box-sizing: border-box;
    border-radius: 2rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: $red-gradient;
      z-index: -3;
      border-radius: 2rem;
    }
    &::after {
      content: "";
      width: calc(100% - 0.4rem);
      height: 96%;
      position: absolute;
      left: 0.2rem;
      top: 0.2rem;
      background: #37364a;
      z-index: -2;
      border-radius: 2rem;
    }

    .noteHeading {
      display: flex;
      align-items: center;
      width: 68%;
      img {
        margin-right: 2.7rem;
      }
      p {
        @extend %typeface-bold-24;
        color: $color-white-0;
        font-family: "Space Grotesk";
      }
      label {
        font-size: 18px;
        padding: 1rem 2rem;
        border: 1px solid $color-white-0;
        border-radius: 2.5rem;
        margin-right: 2.7rem;
        color: $color-white-0;
        font-style: italic;
        font-weight: bold;
      }
    }
    .noteButtons {
      .common-btn {
        &:first-child {
          @include redgradient-border-btn;
          margin-right: 1.3rem;
          span {
            padding: 0.9rem 2.1rem;
            background: #37364a;
            @extend %typeface-semibold-16;
          }
        }
        &:last-child {
          @include white-border-btn;
          padding: 0.9rem 4rem;
        }
      }
    }
  }

  .nftsCollection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3.6rem;
  }
  .nftsHeading {
    a {
      text-decoration: none;
      cursor: pointer;
      @extend %typeface-regular-24;
      position: relative;
      &:first-child {
        margin-right: 5.1rem;
        color: $color-white-0;
        &::after {
          @extend %gradient-border;
          bottom: -0.3rem;
          left: 0;
        }
      }
      &:last-child {
        color: #aaaaaa;
      }
    }
  }
  .nftsButtons {
    a {
      text-decoration: none;
    }
    .common-btn {
      &:first-child {
        @include gradient-btn;
        padding: 0.8rem 1.9rem 0.9rem 1.6rem;
        margin-right: 1.8rem;
      }
      &:last-child {
        @include white-border-btn;
        padding: 0.8rem 1.5rem 0.9rem 1.8rem;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        img {
          margin-right: 0.8rem;
        }
      }
    }
  }

  input[type="text"] {
    height: 100%;
    width: 100%;
    padding: 7px 10px;
    font-size: 13px;
    font-weight: 600;
    outline: none;
    text-align: center;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 50px;
    box-sizing: border-box;
    caret-color: #f20080;
  }

  input[type="text"]::placeholder {
    color: #0c1a28;
    font-size: 13px;
  }

  input[type="text"]:focus,
  input[type="text"]:valid {
    border: 2px solid #7c01f4;
  }

  .tableContainer {
    .revise-table-container {
      width: 95%;
      border-left: 2px solid #bc00b4;
      border-right: 2px solid #bc00b4;
      border-bottom: 2px solid #8601eb;
      .revise-header {
        border-bottom: 2px solid #8601eb;
        border-top: 2px solid #f3007f;
        position: relative;
        .title {
          @extend %typeface-semibold-16;
          color: $color-white-0;
          text-align: center;
          padding: 2.9rem 0 2.4rem;
        }
      }
      .revise-table-data {
        border-collapse: collapse;
        .table-data {
          text-align: center;
          font-size: 14px;
          color: $color-white-0;
          padding: 2rem;
          .common-btn {
            font-size: 12px !important;
            // @include white-border-btn;
            border: 2px solid white;
            padding: 0.6rem;
            margin-right: 0.6rem;
          }
          .common-btn:hover {
            border: 2px solid #fe0074;
          }
        }
      }
      .revise-table-data:nth-of-type(even) {
        background: rgba(255, 255, 255, 0.1);
      }
      .revise-table-data:nth-of-type(odd) {
        background: rgba(103, 103, 103, 0.1);
      }
    }
  }

  hr {
    border-top: 1px solid #636363;
    border-bottom: none;
  }

  .tableContainer {
    display: none;
  }

  .tableContainer.active {
    display: initial;
  }

  .revise-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}

.extraPadding {
  padding-right: 20px;
}
