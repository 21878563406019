@import "../../styles/common.scss";

.registerSection{    
    padding: 7.9rem 0 11.9rem;
    .registerContainer{
        width: 608px;
        max-width: 100%;
        margin: auto;
        background: rgba(255, 255, 255, 0.07);
        border: 2px solid rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(8px);
        border-radius: 8px;
    }
    .registerButtons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        a{
            flex-basis: 50%;
            padding: 3rem 2rem 1.3rem;
            @extend %typeface-regular-16;
            color: $color-white-1;
            letter-spacing: 0.005em;
            position: relative;            
        }
        a::before{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: $color-white-0;
        }
        a.active::before{
            background: $blue-gradient;
        }
        a.active{
            font-weight: 700;
            color: $color-white-0;
        }
    }
    .registerForm{
        padding: 2.7rem 2.6rem 3.9rem;
    }
    h1{
        @extend %typeface-bold-31;
        color: $color-white-0;
        text-align: center;
        padding-bottom: 1.8rem;
    }
    label{
        display: block;
        @extend %typeface-bold-16;
        letter-spacing: 0.005em;
        color: $color-white-0;
        margin-bottom: 0.8rem;
        padding-left: 2rem;
    }
    input{
        display: block;
        width: 100%;
        padding: 1.8rem 2.4rem 1.9rem;
        margin-bottom: 1.7rem;
        border: 1px solid #5541D7;
        border-radius: 0.8rem;
        &::placeholder{
            @extend %typeface-regular-12;
            letter-spacing: 0.005em;
            color: $color-blue-1;
        }
    }
    textarea{
        display: block;
        width: 100%;
        padding: 1.8rem 2.4rem 1.9rem;
        margin-bottom: 1.7rem;
        border: 1px solid #5541D7;
        border-radius: 0.8rem;
        color: white;
        min-height: 150px;
        &::placeholder{
            @extend %typeface-regular-12;
            letter-spacing: 0.005em;
            color: $color-blue-1;
        }
    }
    button{
        display: block;
        width: 100%;         
        background: $blue-gradient;
        padding: 1.45rem;
        border-radius: 0.8rem;
        outline: none;
        border: none;
    }

    @media (min-width: 991px) {
        padding: 15.9rem 0 23.9rem;
        .registerForm{
            padding: 4.7rem 10.4rem 7.8rem;
        }
        .registerButtons{
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            a{
                padding: 4rem 4rem 1.3rem;        
            }
        }
        h1{
            padding-bottom: 3.6rem;
        }
        input{
            &::placeholder{
                font-size: 16px;
            }
        }
    }

}