@import "../../../styles/variables.scss";
@import "../../../styles/common.scss";

.liveTraits {
  &CreateNFTWrapper {
    min-height: calc(100vh - 102px);
    margin-bottom: 50px;
  }

  &NFTFlow{
    @media (min-width:1000px) {
      max-width: 992px;
      margin: 0 auto;
    }
  }

  &NFTFlowHeader {
    @extend %typeface-semibold-32;
    font-weight: 700;
    color: $color-white-0;
    border-bottom: 1px solid #575757;
    padding-top: 50px;
    padding-bottom: 10px;
  }

  &NFTFlowDemoNFTList {
    display: flex;
    margin: 20px 0;
    &ChildOne{
      display: flex;
      flex: 2;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-right: 20px;
      gap: 20px;
    }
    &ChildTwo{
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  &NFTFlowDemoNFTName {
    text-align: center;
    position: relative;
    padding: 15px 20px;
    color: $color-white-0;
    font-size: $fontsize-18;
    font-weight: 600;
    cursor: pointer;
    background: #121212;
    border-radius: 10px;
    flex: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      border: 2px solid transparent;
      background: linear-gradient(
          0deg,
          rgba(255, 0, 0, 1) 0%,
          rgba(7, 0, 211, 1) 100%
        )
        border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
  }

  &ActiveButton{
     text-align: center;
    position: relative;
    padding: 15px 20px;
    color: $color-white-0;
    font-size: $fontsize-18;
    font-weight: 600;
    cursor: pointer;
    background: $blue-gradient;
    border-radius: 10px;
    flex: 1;
  }

   &NFTFlowNFTDetails{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

#footer {
  position: absolute;
  height: 102px;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  object-fit: contain;
}

