$prime-color: rgb(189, 177, 177);
$secondary-color: black;
$gradiant: linear-gradient(
  174.45deg,
  #f4e7d7 -43.99%,
  #f4e7d7 -7.44%,
  #fdfaf7 95.68%
);
$red-gradient: linear-gradient(90deg, #FE0074 4.69%, #7601FA 100%);
$main-gradient: linear-gradient(90deg, #FE0074 4.69%, #7601FA 49.86%, #20BFFC 100%);
$blue-gradient: linear-gradient(90deg, #7601FA 4.69%, #20BFFC 100%);
$text-gradiant: linear-gradient(90deg, #ed1915 24.54%, #fb7103 75.69%);
$prime-color: rgb(189, 177, 177);
$secondary-color: black;

$background-color: radial-gradient(
      25.69% 9.89% at 15.38% 0.92%,
      #000000 0%,
      rgba(0, 0, 0, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      60.57% 60.57% at 32.67% 39.43%,
      rgba(32, 191, 252, 0.2) 0%,
      rgba(32, 191, 252, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      57.66% 57.66% at 96.39% 42.34%,
      rgba(32, 191, 252, 0.2) 0%,
      rgba(32, 191, 252, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      100.9% 51.07% at 0.9% 26.28%,
      rgba(254, 0, 116, 0.2) 0%,
      rgba(254, 0, 116, 0) 81.25%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  #020205;

// colors
$color-black-0: #000000;
$color-black-1: #161616;
$color-success: #5bceae;
$color-error: #f75b5b;
$color-white-0: #ffffff;
$color-white-1: #92929D;
$color-brand-magenta-0: #FE0074;
$color-brand-purple-0: #7601FA;
$color-blue-0: #20BFFC;
$color-blue-1: #9A9AB0;

// fonts
$fontsize-56: 56px;
$fontsize-48: 48px;
$fontsize-40: 40px;
$fontsize-39: 39px;
$fontsize-32: 32px;
$fontsize-31: 31px;
$fontsize-24: 24px;
$fontsize-20: 20px;
$fontsize-18: 18px;
$fontsize-16: 16px;
$fontsize-14: 14px;
$fontsize-12: 12px;

// ****** typeface  ****** //

%typeface-semibold-16{
  font-weight: 600;
  font-size: $fontsize-16;
  line-height: 1.92rem;
}
%typeface-bold-16{
  font-weight: 700;
  font-size: $fontsize-16;
  line-height: 1.92rem;
}
%typeface-regular-16{
  font-weight: 400;
  font-size: $fontsize-16;
  line-height: 2rem;
}
%typeface-bold-12{
  font-weight: 700;
  font-size: $fontsize-12;
  line-height: 1.4rem;
}
%typeface-semibold-12{
  font-weight: 600;
  font-size: $fontsize-12;
  line-height: 1.4rem;
}
%typeface-semibold-14{
  font-weight: 600;
  font-size: $fontsize-14;
  line-height: 1.4rem;
}
%typeface-regular-12{
  font-weight: 400;
  font-size: $fontsize-12;
  line-height: 1.4rem;
}
%typeface-bold-24{
  font-weight: 400;
  font-size: $fontsize-20;
  line-height: 2.6rem;
}
%typeface-semibold-24{
  font-weight: 600;
  font-size: $fontsize-24;
  line-height: 2.9rem;
}
%typeface-regular-24{
  font-weight: 400;
  font-size: $fontsize-24;
  line-height: 3.1rem;
}
%typeface-bold-18{
  font-weight: 700;
  font-size: $fontsize-18;
  line-height: 2.3rem;
}
%typeface-regular-18{
  font-weight: 400;
  font-size: $fontsize-18;
  line-height: 2.3rem;
}
%typeface-regular-48{
  font-weight: 400;
  font-size: $fontsize-48;
  line-height: 6.1rem;
}
%typeface-regular-32{
  font-weight: 400;
  font-size: $fontsize-32;
  line-height: 4.1rem;
}
%typeface-semibold-32{
  font-weight: 600;
  font-size: $fontsize-32;
  line-height: 3.8rem;
}
%typeface-bold-31{
  font-weight: 100;
  font-size: $fontsize-31;
  line-height: 120%;
  letter-spacing: 0.005em;
}
%typeface-bold-39{
  font-weight: 700;
  font-size: $fontsize-39;
  line-height: 120%;
  letter-spacing: 0.005em;
}

@mixin primary_label {
  @extend %typeface-semibold-16;
  color: $color-white-0;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  text-align: center;
  display: block;
}