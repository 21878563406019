@import "../../../styles/common.scss";
@import "../../../styles/variables.scss";

.liveTraitsPopupWrapper {
  .liveTraitsPopup {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .liveTraitsPopupBox {
    background: #000000;
    border-radius: 10px;
    max-width: 60%;
    text-align: center;
    padding: 30px;
    border-top: 3px solid #7601fa;
  }

  .liveTraitsPopupBoxImage {
    margin-bottom: 30px;
  }

  .liveTraitsPopUpText {
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
    // line-height: 1;
    margin-bottom: 35px;
  }
  .liveTraitsPopUpDashboardButton {
    padding: 10px 20px;
    height: 50px;
    background: $blue-gradient;
    border-radius: 30px;
    // font-weight: 700;
    font-size: 16px;
    color: $color-white-0;
    width: 200px;
    margin-right: 20px;
    border: none;
    outline: none;
  }
  .liveTraitsPopUpRetryButton {
    border: 2px solid $color-white-0;
    padding: 10px 20px;
    height: 50px;
    border-radius: 30px;
    // font-weight: 700;
    font-size: 16px;
    background: black;
    color: $color-white-0;
    width: 200px;
    outline: none;
  }
}

@media (min-width:1000px) {
  .liveTraitsPopupBox{
width: 35%;
  }
}