// Application level common styles

@import "./variables.scss";

//Fonts
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0px;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}
html{
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: 'Space Grotesk', sans-serif;
  background: radial-gradient(
        25.69% 9.89% at 15.38% 0.92%,
        #000000 0%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        60.57% 60.57% at 32.67% 39.43%,
        rgba(32, 191, 252, 0.2) 0%,
        rgba(32, 191, 252, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        57.66% 57.66% at 96.39% 42.34%,
        rgba(32, 191, 252, 0.2) 0%,
        rgba(32, 191, 252, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        100.9% 51.07% at 0.9% 26.28%,
        rgba(254, 0, 116, 0.2) 0%,
        rgba(254, 0, 116, 0) 81.25%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #020205 !important;
}

.container {
  width: 100%;
  padding: 0 2.4rem;
  margin: auto;
  @media only screen and (min-width: 1250px) {
    max-width: 1110px;
    padding: 0;
  }
}

.commonHeading {
  margin-bottom: 3.4rem;
  @media only screen and (max-width: 768px) {
    margin-bottom: 2.8rem;
  }
  label {
    @include primary_label;
  }
}

.common-btn{
  cursor: pointer;
  display: inline-block;
  border-radius: 3rem;
  @extend %typeface-semibold-16;
  color: $color-white-0;
  text-align: center
}

.common-btn span {
  @extend %typeface-semibold-12;
  padding: 0.8rem 1.2rem;
  border-radius: 3rem;
  color: $color-white-0;
}

%gradient-border{
  content: "";
  position: absolute;
  width: 100%;
  height: 0.2rem;
  background: $red-gradient;
  z-index: -1;
  border-radius: 0;
}

.gradient-border{
  position: relative;
}

.gradient-border::after {
  content: "";
  display: block;
  position: absolute;
  top: -0.2rem;
  left: -0.2rem;
  z-index: -1;
  width: 103.3%;
  height: 110%;
  border-radius: 3rem;
}

a {
  text-decoration: none !important;
}

/* Gradient Scrollbar */
body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background:  linear-gradient(0deg, rgba(255, 0, 0, 1) 0%, rgba(7, 0, 211, 1) 100%);
}

body::-webkit-scrollbar-thumb {
  background: transparent; /* opacity: 0; should do the thing either */
  box-shadow: 0px 0px 0px 100000vh black;
}


@mixin redgradient-border-btn {  
  .gradient-border::after {
    background: $red-gradient;
  }
}

@mixin white-border-btn {
  border: 2px solid $color-white-0;
}

@mixin gradient-btn {
  background: $red-gradient;
}

.update-btn-wrapper a,a:hover,a:focus{
 color: #fff !important;
 text-decoration: none !important; 
}

label.logKeys{
  font-size: 16px;
  padding: 2px;
  font-weight: 700;
  color: #FE0072;
}
span.logValues{
  font-size: 14px;
  padding: 2px;
}


.page-link{
  font-size: 14px !important;
  color: #122B36 !important;
}
.active>.page-link {
  background-color: #7E01F1 !important;
  border-color: #7E01F1 !important;
  color: white !important;
}

// .page-link{
//   font-size: 15px !important;
// }

.page-link:hover, .page-link:focus{
  color: #9ea0a1 !important;
}

.modalBg .modal-content{
  background: 
    radial-gradient(
        60.57% 60.57% at 32.67% 39.43%,
        rgba(32, 191, 252, 0.2) 0%,
        rgba(32, 191, 252, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        57.66% 57.66% at 96.39% 42.34%,
        rgba(32, 191, 252, 0.2) 0%,
        rgba(32, 191, 252, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
 
    #020205 !important;
  font-size: 14px;
  color: #fff !important;
}

.modalFilter{
  // background: rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 3px;
  max-height: 70vh;
}

.modalImg{
  width: 25rem;
  height: 28rem;
  margin: 0 auto;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 21rem;
  }
}
.collectionImg{
  text-align: center;
  height: 28rem;
  .imageWrapper{
    width: 20rem;
    height: 20rem;
    margin: 0 auto 2rem;
  }
  img{
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
  @media only screen and (max-width: 600px) {
    height: 21rem;
  }
}

.modalRow {
  max-height: 65vh;
  overflow-y: scroll;
  .imageWrapper{
    width: 20rem;
    height: 20rem;
    margin: 0 auto 2rem;
  }
}
.modalRow::-webkit-scrollbar {
  width: none;
  display: none;
}

@media screen and (max-width: 768px) {
  .modalFilter .modalRow {
    max-height: 21vh;
  }
}

@media screen and (max-width: 480px) {
  .modalFilter .modalRow {
    max-height: 20vh;
  }
  .collectionImg{
    width: 60% !important;
  }
}

