@import '/src/styles/variables.scss';
@import '/src/styles/common.scss';

.guide{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69));
    z-index: 1;
    .container{
        width: 100%;
        height: 100%;
    }
    &Body{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
    &Containter{
        max-width: 1110px;
        margin: auto;
        background: #121212;
        border: 1px solid #464646;
        box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 35px 68px 35px 37px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-height: 623px;
    }
    &Img{
        flex-basis: 55%;
    }
    img{
        max-width: 100%;
    }

    &Content{
        flex-basis: 41.5%;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        label{
            font-family: 'Manrope';
            font-weight: 700;
            font-size: 20px;
            line-height: 150%;
            color: #D7FF3A;
            padding-bottom: 5px;
        }
        h2{
            font-family: 'Manrope';
            font-weight: 700;
            font-size: 48px;
            line-height: 110%;
            letter-spacing: -1px;
            background: linear-gradient(180deg, #FFFFFF 0%, #C0C0C0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 18px;
            max-width: 300px;
        }
        p{
            font-family: 'Manrope';
            font-weight: 500;
            font-size: 24px;
            line-height: 150%;
            color: #EDEDED;
            max-width: 380px;
            &:not(:last-child){
                padding-bottom: 12px;
            }
        }
    }
    &Next{
        // padding-top: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a{
            font-family: 'Manrope';
            font-weight: 700;
            font-size: 20px;
            line-height: 150%;
            color: #121212;
            padding: 18px 50px;
            background: #D7FF3A;
            border-radius: 47.5px;
            display: flex;
            align-items: center;
            gap: 10px;
            width: fit-content;
            cursor: pointer;
            &:hover{
                color: #121212 !important;
            }
        }
        .dots{
            display: flex;
            gap: 15px;
            align-items: center;
            span{
                width: 12px;
                height: 12px;
                background: #D9D9D9;
                border-radius: 50%;
                display: inline-block;
            }
            .active{
                background: #056AF9;
                width: 14px;
                height: 14px;
            }
        }
    }
}