@import "../../styles/common.scss";

.successSection{    
    .imageWrapper{
        width: 15.1rem;
        max-width: 100%;
        height: 18.4rem;
        margin: auto;
        margin-bottom: 3rem;
        img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    h1{
        @extend %typeface-bold-31;
        color: $color-white-0;
        text-align: center;
        padding-bottom: 3.6rem;
    }
    button{
        display: block;
        width: 100%;         
        background: $blue-gradient;
        padding: 1.45rem;
        border-radius: 0.8rem;
        outline: none;
        border: none;
    }

    @media (min-width: 991px) {
        .registerForm{
            padding: 5.1rem 10.4rem;
        }
    }

}