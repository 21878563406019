@import "../../../../styles/variables.scss";
@import "../../../../styles/common.scss";

.liveTraitsNFTFlowNFTDetails {
  &LeftBlock {
    color: white;
    flex: 2;
    border-radius: 10px;
    background-color: #121212;
    padding: 30px;

    .nameTitle {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 2px;
      color: #ffffff;
      text-transform: uppercase;
    }
    .name {
      font-weight: 700;
      font-size: 32px;
      color: #ffffff;
    }
    .descriptionTitle {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #ffffff;
    }
    .description {
      font-weight: normal;
      font-size: 18px;
      color: #9a9a9a;
      line-height: 1.3;
    }
    .propertiesWrapperTitle {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .properties {
      display: flex;
    }

    .propertiesDataWrapper {
      background: #1f1f1f;
      border: 1px solid #3d3d3d;
      border-radius: 10px;
      text-align: center;
      line-height: 1;
      padding: 10px 20px;
      margin-right: 20px;
    }

    .propertiesTitle {
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #ffffff;
    }
    .propertiesData {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      color: #ffffff;
    }
    .button {
      background: $blue-gradient;
      width: 100%;
      padding: 11px 45px;
      height: 52px;
      border-radius: 80px;
      color: $color-white-0;
      font-size: $fontsize-18;
      border: none;
      outline: none;
    }
  }

  &RightBlock {
    padding: 20px;
    background-color: blue;
    color: white;
    border-radius: 10px;
    background-color: #121212;
    flex: 1;
    &Header {
      font-weight: 700;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
      height: fit-content;
    }

    .nftImageWrapper {
      z-index: 0;
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      background: #464646;

      .nft-image-background {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
        width: 100%;
        height: 100%;
        -webkit-filter: blur(10px); /* Safari 6.0 - 9.0 */
        filter: blur(10px);
      }

      .nft-image {
        object-fit: contain;
        width: 200px;
        height: 200px;
        border-radius: 30%;
      }
    }
    .nft {
      & > * {
        margin-bottom: 10px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .rightBLockNFTDetailsWrapper {
      background: #d7ff3a;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      padding: 5px;
    }
    .rightBLockNFTDetailsTitle {
      font-weight: 700;
      font-size: 16px;
      color: #121212;
    }
    .rightBLockNFTDetailsData {
      font-weight: 700;
      font-size: 16px;
      color: #121212;
    }
  }

  .nftDetailsWrapper {
    position: relative;
    margin: 50px 0;
    background-color: #464646;
    padding: 12px;
    border-radius: 10px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 8px; /* added */
      width: 0;
      border-left: 132px solid transparent;
      border-right: 133px solid transparent;
    }
    &::before {
      bottom: 100%;
      border-bottom: 25px solid #464646;
    }
    &::after {
      top: 100%;
      width: 0;
      border-top: 25px solid #464646;
    }
  }
}
