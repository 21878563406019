@import "../../styles/common.scss";

.tableContainer {
  padding-bottom: 9.6rem;

  table {
    width: 100%;
  }
  th.title {
    @extend %typeface-semibold-16;
    color: $color-white-0;
    padding: 20px;
    text-align: center;
    // text-align: left;
    // flex-basis: 16%;
  }
  td.fields {
    text-align: center;
    // @extend %typeface-semibold-16;
    font-size: 14px;
    color: $color-white-0;
    padding: 20px;
    // flex-basis: 16%;
  }

  td.fields .deleteActiveImg {
    cursor: pointer;
  }

  tbody tr:nth-of-type(even) {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
  }
  tbody tr:nth-of-type(odd) {
    background: rgba(103, 103, 103, 0.1);
  }
}

// END OF TABLE

.generateapi {
  display: flex;
  max-width: 1400px;
  margin: auto;
  padding: 0 2.4rem;
  .generateapiKeys {
    width: 100%;
    margin-right: 2.9rem;
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-radius: 0.8rem;
  }
  .generateapiHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
    h2 {
      //   @extend %typeface-bold-39;
      color: $color-white-0;
    }
    p {
      background: $blue-gradient;
      padding: 1rem;
      border-radius: 0.8rem;
    }
  }

  .revise-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .generateapiTip {
    flex-shrink: 0;
    width: 340px;
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-radius: 0.8rem;
    text-align: center;
    padding: 3.1rem 2.8rem 2.7rem 3rem;
    height: fit-content;
    img {
      padding-bottom: 0.9rem;
    }
    h3 {
      @extend %typeface-bold-31;
      color: $color-white-0;
      padding-bottom: 4.1rem;
    }
    p {
      @extend %typeface-regular-24;
      letter-spacing: 0.005em;
      line-height: 120%;
      color: $color-white-0;
      padding-bottom: 4rem;
    }
    a {
      color: $color-white-0;
      padding: 1.8rem;
      border: 2px solid $color-white-0;
      border-radius: 1.3rem;
      letter-spacing: 0.005em;
      display: block;
      width: 100%;
    }
  }
}

.deactivate-modal {
  p {
    // @extend %typeface-regular-16;
    color: $color-white-1;
    font-size: 14px;
    text-align: justify;
    text-align-last: center;
  }
}
