@import "../../styles/common.scss";

footer {   
  .footerWrapper{    
    padding: 2.8rem 0 4.5rem; 
    display: flex;
    justify-content: space-between;
  }
  p{
      @extend %typeface-semibold-16;
      color: $color-white-0;
white-space: nowrap;  }
}
