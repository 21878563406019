@import "../../../styles/variables.scss";
@import "../../../styles/common.scss";

.login{
  height: 100vh;
  background: url(../../../assets/images/login/lines.png), #202020;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &Head{
    padding: 16px 28px;
    a{
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: #FFFFFF;
      font-family: 'Manrope';
      gap : 12px;
      display: flex;
      align-items: center;
    }
  }
  &Data{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
  }
  &Container{
    max-width: 912px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  &Content{
    flex-basis: 44%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1{
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 48px;
      line-height: 127%;
      color: #FFFFFF;
      letter-spacing: -1px;
    }
  }
  &Form{
    flex-basis: 48%;
    padding: 50px 69px;
    background: #D7FF3A;
    border: 1px solid #BCBCBC;
    box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    h2{
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 32px;
      line-height: 115%;
      color: #202020;
      letter-spacing: -1px;
      padding-bottom: 28px;
    }
    .inputField{
      padding-bottom: 28px;
      position: relative;
    }
    input{
      width: 100%;
      padding: 10px 14px;
      border: 1px solid #000000;
      border-radius: 8px;
      font-family: 'Manrope';
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      color: #000000;
      background-color: transparent;
      &::placeholder{
        font-family: 'Manrope';
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: #5E7017;
      }
      &:focus-visible{
        outline: none;
      }
    }
    .formSubmit{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 28px;
      a{
        font-family: 'Manrope';
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        text-decoration: underline !important;
        &:hover{
          color: #000000 !important;
        }
      }
      button{
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 18px 50px;
        background: #056AF9;
        border-radius: 47.5px;
        outline: none;
        border: none;
        cursor: pointer;
        img{
          transform: rotate(180deg);
        }
      }
    }
    p{
      font-family: 'Manrope';
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #87A023;
      a{
        color: #87A023;
        text-decoration: underline !important;
        &:hover{
          color: #87A023 !important;
        }
      }
    }
  }
  .copyRights{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1110px;
    margin: 0 auto;
    width: 100%;
    padding: 18px 0;
    p{
      font-family: 'Manrope';
      font-weight: 400;
      font-size: 18px;
      line-height: 162%;
      letter-spacing: 0.008em;
      color: #7A7A7A;
    }
  }
  .error-message {
    font-size: 14px !important;
    margin: 0;
    color: red !important;
    position: absolute;
    bottom: 0;
    left: 0;
}
}
