.loaderCustom{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #EDEDED;
    &.small{
        position: static;
    }
    .loaderImg{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        img{
            transform: rotate(0deg);
            transition: transform 0.5s linear;
            animation: rotateImg 2s infinite linear;
            width: 100px;
        }
    }
}

@keyframes rotateImg{
    from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(359deg);
      }
}