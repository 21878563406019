@import "../../styles/common.scss";

.header {
  padding: 21px 0 19px;
  border-bottom: 1px solid rgba(174, 174, 174, 0.24);
  .container{
    display:  flex;
    align-items: center;
    justify-content: space-between;
  }
  img{
    vertical-align: middle;
  }
  a{
    text-decoration: none;
    display: inline-block;    
  }
  ul{
    display: flex;
    margin-left: auto;
    align-items: center;
    a{
      text-decoration: none;
      @extend %typeface-semibold-12;
      color: $color-white-0;
      padding-right: 2.3rem            
    }    
  }
  .common-btn {    
    @include redgradient-border-btn;

    span{
      background: $color-black-1;
    }
    .gradient-border::after{
      width: 106%;
    }
  }
}